<template>
  <div>
    <Navbar />

    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    <PageTitle
      :title="'Car expense detail'"
      :subtitle="'Information about car expense'"
    />

    <section class="section">
      <div class="container">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              <b-icon icon="cash"></b-icon>
              Expense
            </p>
          </header>
          <div class="card-content">
            <div class="content">
              <label class="label">Date</label>
              <div class="is-flex">
                <b-icon icon="calendar"></b-icon>
                <p>
                  {{ new Date(this.carExpense.createdAt).toLocaleDateString() }}
                </p>
              </div>

              <label class="label">Expense type</label>
              <div class="is-flex">
                <b-icon icon="hammer"></b-icon>
                <p v-if="this.carExpense.type">
                  {{ this.carExpense.type.name }}
                </p>
              </div>

              <label class="label">Cost</label>
              <div class="is-flex">
                <b-icon icon="cash"></b-icon>
                <p>{{ this.carExpense.cost }} €</p>
              </div>

              <label class="label">Description</label>
              <div class="is-flex">
                <b-icon icon="note"></b-icon>
                <p>{{ this.carExpense.description }}</p>
              </div>

              <label class="label">Car mileage</label>
              <div class="is-flex">
                <b-icon icon="speedometer"></b-icon>
                <p>{{ this.carExpense.carMileage }}</p>
              </div>

              <label class="label">Receipt/Document</label>
              <div class="is-flex">
                <b-icon icon="note"></b-icon>

                <figure v-if="this.imagePath" class="image">
                  <img :src="this.baseUrl + this.imagePath" />
                </figure>
              </div>
            </div>
          </div>
          <footer class="card-footer">
            <b-button
              @click="confirmExpenseDelete($event)"
              class="card-footer-item"
              type="is-danger"
              size="large"
              icon-left="delete"
              outlined
            >
              Delete
            </b-button>
          </footer>
        </div>
      </div>
    </section>

    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { httpClient } from '@/api/httpClient.js'
import { httpImageClient } from '@/api/httpClient.js'
import PageTitle from '@/components/PageTitle.vue'

export default {
  components: {
    Navbar,
    Footer,
    PageTitle
  },
  data() {
    return {
      isLoading: false,
      carExpense: {},
      imagePath: null,
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  props: {
    carExpenseId: {
      type: String
    }
  },
  created() {
    this.getCarExpenseInfo()
  },
  methods: {
    getCarExpenseInfo() {
      this.isLoading = true

      httpClient
        .get(`car-expenses/${this.carExpenseId}`)
        .then(res => {
          this.isLoading = false

          this.carExpense = res.data
          if (this.carExpense.image) this.getImageForUri(res.data.image)
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    getImageForUri(uri) {
      httpImageClient
        .get(uri)
        .then(res => {
          this.imagePath = res.data.contentUrl
        })
        .catch(err => {
          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    confirmExpenseDelete(event) {
      event.stopPropagation()

      this.$buefy.dialog.confirm({
        title: 'Delete expense',
        message: 'Are you sure you want to this car expense?',
        confirmText: 'Delete expense',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteExpense(this.carExpenseId)
      })
    },
    deleteExpense(carExpenseId) {
      this.isLoading = true

      httpClient
        .delete(`/car-expenses/${carExpenseId}`)
        .then(() => {
          this.isLoading = false
          const carExpenseUriArr = this.carExpense.car.split('/')
          this.$router.replace({
            name: 'car',
            params: { carId: carExpenseUriArr[carExpenseUriArr.length - 1] }
          })

          this.$buefy.snackbar.open({
            message: `Car expense has been deleted.`,
            type: 'is-success',
            position: 'is-top',
            actionText: 'OK'
          })
        })
        .catch(err => {
          this.isLoading = false

          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
